import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { media } from "utils/Media";
import MainNav from "components/functional/mainNav";

import Layout from "components/layout";

import { StaticImage } from "gatsby-plugin-image";

const metaData = {
  title: "DEWYNTERS DIGEST February 2025 Edition | HERE COMES THE PUN  | D E W Y N T E R S",
  description:
    " A pandemic, train strikes, the cost of living crisis...the past few years have shown us that nothing stops a dedicated theatre fan from seeing their favourite shows.",
  image: "images/news/redefining.png",
};

const NewsBody = styled.section`
  width: 100%;

  .news {
    width: 85%;
    margin: var(--auto);
    max-width: 900px;
    @media ${media.sm} {
      padding-top: 2rem;
    }
  }

  .app-img {
    margin-bottom: 1rem;
  }
`;
const SingleNewsStyles = styled.article`
  position: relative;
  width: 100%;
  margin-bottom: 4rem;

  .gatsby-image-wrapper {
    width: 100%;
    height: auto;
  }

  svg {
    width: 30px;
    height: 30px;
    fill: var(--cyan);
    margin-bottom: 0.5rem;
  }

  h1 {
    font-weight: 800;
    font-size: 1.5rem;

    span {
      color: var(--cyan);
    }
    @media ${media.md} {
      font-size: 33px;
    }
  }

  .body {
    margin-top: 1rem;
    .highlight {
      color: #09dbd8;
    }
    a {
      text-decoration: underline 2px;
      color: #09dbd8 !important;
      &:hover,
      &:active {
        transform: scale(1.5);
      }
    }
    .no-colour-change {
      color: #000 !important;
    }
    p {
      margin-bottom: 1rem;

      strong {
        font-weight: 900;
        font-family: var(--PoppinsBold);
      }

      span,
      a {
        color: var(--cyan);
        font-weight: 800;
      }
    }
  }

  @media ${media.sm} {
    .gatsby-image-wrapper {
      height: auto;
    }
  }
  @media ${media.xl} {
    .gatsby-image-wrapper {
      height: auto;
    }
  }
`;
const LatestNews = ({ data }) => {
  return (
    <Layout
      title={metaData.title}
      description={metaData.description}
      image={metaData.image}
    >
      <MainNav alt />
      <NewsBody>
        <div className="news">
          <SingleNewsStyles>
            <div className="header">
              <StaticImage
                src="../../images/news/redefining-theatre-audiences-the-new-faces-of-frequent-theatregoers.jpg"
                alt="Redefining Theatre Audiences: The New Faces of Frequent Theatregoers  | Dewynters"
                className="app-img"
              />
              <h1>
                Redefining Theatre Audiences: The New Faces of Frequent
                Theatregoers
              </h1>
            </div>
            <div className="body">
              <p>
                A pandemic, train strikes, the cost of living crisis...the past
                few years have shown us that <em>nothing</em> stops a dedicated
                theatre fan from seeing their favourite shows.
              </p>
              <p>But who are our most ardent theatregoers?</p>
              <p>
                <em>
                  &nbsp;&ldquo;Nice, respectable, middle-class, middle-aged,
                  maiden lady, with time on her hands and the money to help her
                  pass it. She enjoys pictures, books, music and the
                  theatre&rdquo;.&nbsp;
                </em>
                &nbsp;
              </p>
              <p>
                <strong>
                  Terrence Rattigan, English playwright, 1953&nbsp;
                </strong>
                &nbsp;
              </p>
              <p>
                This quaint stereotype of the average theatregoer from 70 years
                ago still has some truth in data today: in{" "}
                <a
                  href="https://www.broadwayleague.com/research/research-reports/"
                  target="_blank"
                >
                  Broadway&rsquo;s 2018-2019 season
                </a>
                , 68% of audiences were women, while the average age of the
                Broadway theatregoer has hovered between 40 and 45-years-old for
                the past two decades.&nbsp;
              </p>
              <p>
                However, the latest YouGov data on theatre attendance has
                unveiled some unexpected findings in the post-pandemic world.{" "}
                <strong>Things are starting to shift.</strong>&nbsp;
              </p>
              <p>
                But to start off with: what do we mean when we talk about
                &ldquo;frequent theatregoers&rdquo;?
              </p>
              <p>
                Around 14% of the UK population (9.4 million people) go to the
                theatre at least twice a year. Plays are slightly more popular
                than musicals, with 17% of the UK seeing a play at least twice a
                year.&nbsp;
              </p>
              <div className="image">
                <StaticImage
                  layout="fullWidth"
                  src="../../images/news/graphs/1-graph.png"
                  alt="Redefining Theatre Audiences: The New Faces of Frequent Theatregoers  | Dewynters"
                />
              </div>
              <p>
                Challenging the stereotype that theatre is predominantly a
                female pastime, 57% of frequent theatregoers are men.
              </p>
              <p>
                While women aged 65+ still constitute the largest demographic in
                pure numbers (about 1.1 million people are female theatregoers
                aged 65+), this is mostly down to the UK&rsquo;s growing ageing
                population. Actually, only 14% of women in this age group
                regularly go to the theatre.
              </p>
              <p>
                A surprising trend emerges when we look at younger theatregoers:
                an impressive{" "}
                <strong>
                  27% of 18-24 year olds go to the theatre at least twice a year
                </strong>
                . What&rsquo;s even more remarkable is that there is a gender
                balance here: this is evenly matched across both men and women.
              </p>
              <div className="image">
                <StaticImage
                  layout="fullWidth"
                  src="../../images/news/graphs/2-graph.png"
                  alt="Redefining Theatre Audiences: The New Faces of Frequent Theatregoers  | Dewynters"
                />
              </div>
              <p>
                So, the future for theatre is definitely looking bright.
                Millennials and Gen Z over-indexed the most for theatre
                attendance despite the cost-of-living crisis affecting them more
                than older groups.
              </p>
              <p>
                This younger audience was also reflected in their other
                demographics: they are more than twice as likely to be a member
                of the LGBTQ+ community and 89% live in an urban area.
              </p>
              <p>
                So how do we speak to these new, emerging younger theatregoers
                in a way that is authentic and relatable? Well, we need to start
                by understanding their lifestyle and motivations.
              </p>
              <p>
                Younger generations have had a pretty rough time compared to
                previous generations. Millennials in particular entered young
                adulthood in the midst of the 2008 financial crisis and are
                expected to be{" "}
                <a
                  href="https://www.ft.com/content/81343d9e-187b-11e8-9e9c-25c814761640"
                  target="_blank"
                >
                  the first generation who are worse off than their parents
                </a>
                .
              </p>
              <p>
                So what does that mean for their lifestyle and entertainment
                choices?
              </p>
              <p>
                <strong>Meaningful Downtime</strong>
              </p>
              <p>
                People in the UK work some of the longest hours in Europe.
                Because of this, downtime is immensely valuable and must have a
                clear purpose. Younger people are more likely to prioritise
                something that releases pressure and makes them feel good. In
                the theatre landscape, we&rsquo;ve seen this reflected in the
                popularity of well-known, feel-good shows: think{" "}
                <em>
                  Back to the Future, Moulin Rouge! The Musical, Magic Mike Live
                  and the recently announced </em> <em>The Devil Wears Prada</em>
                
                .
              </p>
              <p>
                <strong>The Lipstick Effect</strong>
              </p>
              <p>
                If a Millennial/Gen Xer received a pound for every time
                they&rsquo;ve been told{" "}
                <a
                  href="https://www.buzzfeed.com/tahliapritchard/i-dont-even-like-avo-wheres-my-damn-house"
                  target="_blank"
                >
                  &ldquo;if you want a house, stop buying avocado toast&rdquo;
                </a>
                , they might actually be able to get on the housing market. But
                this popular debunked theory gives way to the more reliable
                'Lipstick Effect'.
              </p>
              <p>
                The lipstick effect is the idea that during economic downturns
                where saving for a house deposit is a multi-year endeavour,
                consumers tend to opt for more affordable luxury treats, such as
                a night out at the theatre.
              </p>
              <p>
                In response to this, low-cost ticket initiatives have proved
                popular, such as Operation Mincemeat&rsquo;s &pound;39.50 Monday
                ballot and TodayTix&rsquo;s Rush tickets.
              </p>
              <p>
                <strong>The Influencer Generation</strong>
              </p>
              <p>
                Younger generations live their lives online. In fact, 31% of
                18-34 year olds spend more than 24 hours of their week online.
                Because of this, how a show markets itself on social media is
                incredibly important (you can read more about the changing
                landscape of social media{" "}
                <a
                  href="https://www.dewynters.com/opinion/the-clock-might-be-ticking-on-tiktok-but-its-definitely-our-friend-for-now/"
                  target="_blank"
                >
                  here
                </a>
                ).
              </p>
              <p>
                Younger audiences are using their social media to become
                mini-influencers: sharing their values, outlook on life and
                experiences with their social circles. According to
                YouGov&rsquo;s consumer personalities segmentation, 26% of
                frequent theatregoers are Curious Influencers (14% higher than
                the national average).
              </p>
              <div className="image">
                <StaticImage
                  layout="fullWidth"
                  src="../../images/news/graphs/3-graph.png"
                  alt="Redefining Theatre Audiences: The New Faces of Frequent Theatregoers  | Dewynters"
                />
              </div>
              <p>
                What can we expect from a Curious Influencer in the world of
                theatre? Well, 62% like to stay up-to-date on the latest shows
                (so expect to see them on your sign-up list for news) and 76%
                like to recommend shows to their friends and family.
                They&rsquo;re also more likely to be influenced by other
                people&rsquo;s recommendations.
              </p>
              <p>
                Curious Influencers are also active in the digital realm,
                especially discovery channels like TikTok and Instagram: they
                follow shows on social media, engage with paid social ads and
                don&rsquo;t find ads annoying; they particularly enjoy video
                content.
              </p>
              <p>
                <strong>Adapting to Theatre&rsquo;s Changing Audiences</strong>
              </p>
              <p>
                The theatre landscape is evolving and challenging stereotypes of
                who frequent theatregoers are. The exciting opportunity for
                shows and theatre marketers is how we can learn from this and
                appeal to younger generations without alienating older
                audiences.
              </p>
              <p>
                In the next Digest we&rsquo;ll be looking closer at these
                younger audiences and how we create fanbases both on and
                offline.
              </p>
            </div>
            <p>
              <strong>
                <Link to="/opinion/">{`< Back`}</Link>
              </strong>
            </p>
          </SingleNewsStyles>
        </div>
      </NewsBody>
    </Layout>
  );
};

LatestNews.propTypes = {
  data: PropTypes.shape({
    News: PropTypes.any,
  }),
};

export default LatestNews;
